<template>
  <div>
    <b-field grouped group-multiline>
      <b-select v-model="perPage" size="is-small">
        <option value="2">2</option>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </b-select>
      <b-button
        :loading="isLoading"
        @click="getTeachersToStorage"
        icon-left="refresh"
        size="is-small"
      >{{t('common.refresh')}}</b-button>
    </b-field>
    <b-table
      :data="filteredTeachers"
      :loading="isLoading"
      striped
      paginated
      :per-page="perPage"
      checkable
      :checked-rows.sync="checkedRows"
    >
      <b-table-column
        field="teacherId"
        :label="t('common.teacherId')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.teacherId }}</b-table-column>
      <b-table-column
        field="firstName"
        :label="t('common.firstName')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.firstName }}</b-table-column>
      <b-table-column
        field="lastName"
        :label="t('common.lastName')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.lastName }}</b-table-column>
      <b-table-column
        field="teacherAddress"
        :label="t('common.address')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.teacherAddress }}</b-table-column>
    </b-table>
    <div class="pb-5">
      <b-button
        icon-left="check"
        type="is-success"
        :loading="isLoading"
        @click="save"
      >{{t('common.save')}}</b-button>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";

export default {
  name: "ClassroomCreatorTeachers",
  mixins: [CommonHelpers],
  components: {},

  props: {
    idTeacher: { type: String, required: false },
    idTeachers: { type: Array, required: false },
    multiCheck: { type: Boolean, required: false },
    availableTeachers: { type: Array, required: false },
  },

  data: function () {
    return {
      //teachers: [],
      perPage: 50,
      checkedRows: [],
      isLoading: false,
    };
  },

  mounted() {
    this.getTeachersToStorage();
  },

  watch: {
    checkedRows: function (newValue, oldValue) {
      let difference;
      if (newValue.length > oldValue.length) {
        difference = newValue.filter((x) => !oldValue.includes(x));
        if (this.multiCheck) {
          this.addTeacherToClassroom(difference);
        } else {
          this.checkedRows = difference;
        }
      } else {
        difference = oldValue.filter((x) => !newValue.includes(x));
        if (this.multiCheck) {
          this.removeTeacherFromClassroom(difference);
        } else {
          //do nothing
        }
      }

      if (!this.multiCheck) {
        if (this.checkedRows.length > 0) {
          this.selectionTeacher(this.checkedRows[0]);
        } else {
          this.selectionTeacher({});
        }
      }
    },

    idTeachers() {
      if (this.filteredTeachers.length > 0) {
        this.addCheckedTeacher();
      }
    },

    idTeacher() {
      if (this.idTeacher != null) {
        if (this.filteredTeachers.length > 0) {
          this.addCheckedTeacher();
        }
      }
    }
  },

  computed: {
    filteredTeachers() {
      if (this.$store.state.teacher.teachers) {
        if (this.availableTeachers) {
          let filtered = [];
          this.availableTeachers.forEach((teachId) => {
            let teacher = this.$store.state.teacher.teachers.find(
              (t) => t.id === teachId
            );
            if (teacher) {
              filtered.push(teacher);
            }
          });
          return filtered;
        } else {
          return this.$store.state.teacher.teachers;
        }
      } else return [];
    },
  },

  methods: {

    getTeachersToStorage() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.TEACHER_GET_ALL)
        .then(() => {
          this.addCheckedTeacher()
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addCheckedTeacher() {
      if (this.idTeacher != null || this.idTeachers) {
        if (this.multiCheck) {
          this.idTeachers.forEach((id) => {
            let teacher = this.filteredTeachers.find((s) => s.id === id);
            if (teacher) {
              this.checkedRows.push(teacher);
            }
          });
        } else {
          if (this.idTeacher != null) {
            let teacher = this.filteredTeachers.find(
              (s) => s.id === this.idTeacher
            );
            if (teacher) {
              this.checkedRows = [];
              this.checkedRows.push(teacher);
            } else {
              this.mDangerSnack(this.t('validation.teacherNotFound'));
            }
          }
        }
      }
    },

    save() {
      this.$emit("save");
    },

    addTeacherToClassroom(teachersChecked) {
      this.isLoading = true;
      let idTeachers = teachersChecked.map((q) => q.id);
      this.$emit("addTeachers", idTeachers);
      this.isLoading = false;
    },

    removeTeacherFromClassroom(teachersChecked) {
      let idTeachers = teachersChecked.map((q) => q.id);
      this.$emit("removeTeachers", idTeachers);
    },

    selectionTeacher(teacher) {
      this.$emit("setTeacher", {
        id: teacher.id,
        firstName: teacher.firstName,
        lastName: teacher.lastName,
      });
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: green;
}
.exam-modal {
  background-color: white;
}
</style>