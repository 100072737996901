<template>
  <div>
    <b-field grouped group-multiline>
      <b-select v-model="perPage" size="is-small">
        <option value="2">2</option>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </b-select>
      <b-button
        :loading="isLoading"
        @click="getStudentsToStorage"
        size="is-small"
        icon-left="refresh"
      >{{t('common.refresh')}}</b-button>
    </b-field>
    <b-table
      :data="filteredStudents"
      :show-detail-icon="showDetailIcon"
      detailed
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
      :loading="isLoading"
      striped
      paginated
      :per-page="perPage"
      checkable
      :checked-rows.sync="checkedRows"
    >
      <b-table-column
        field="studentIndex"
        :label="t('common.studentIndex')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentIndex }}</b-table-column>
      <b-table-column
        field="firstName"
        :label="t('common.firstName')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.firstName }}</b-table-column>
      <b-table-column
        field="lastName"
        :label="t('common.lastName')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.lastName }}</b-table-column>
      <b-table-column
        field="studentStudy"
        :label="t('common.study')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentStudy }}</b-table-column>
      <b-table-column
        field="studentYear"
        :label="t('common.year')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentYear }}</b-table-column>
      <b-table-column
        field="studentSemester"
        :label="t('common.semester')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.studentSemester }}</b-table-column>
      <b-table-column field="studentGroups" searchable sortable :label="t('common.groups')" v-slot="props">
        <b-taglist>
          <b-tag v-for="group in props.row.studentGroups" :key="group" size="is-small">{{ group }}</b-tag>
        </b-taglist>
      </b-table-column>
    </b-table>
    <div class="pb-5">
      <b-button
        icon-left="check"
        type="is-success"
        :loading="isLoading"
        @click="save"
      >{{t('common.save')}}</b-button>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";

export default {
  name: "ClassroomCreatorStudents",
  mixins: [CommonHelpers],
  components: {},

  props: {
    idStudents: { type: Array, required: false },
    availableStudents: { type: Array, required: false },
  },

  data: function () {
    return {
      questions: [],
      perPage: 50,
      showDetailIcon: false,
      defaultOpenedDetails: [0],
      checkedRows: [],
      isLoading: false,
      isModalActive: false,
      showExamId: null,
    };
  },

  mounted() {
    this.getStudentsToStorage();
  },

  watch: {
    checkedRows: function (newValue, oldValue) {
      let difference;
      if (newValue.length > oldValue.length) {
        difference = newValue.filter((x) => !oldValue.includes(x));
        this.addStudentToClassroom(difference);
      } else {
        difference = oldValue.filter((x) => !newValue.includes(x));
        this.removeStudentFromClassroom(difference);
      }
    },
    // availableStudents() {
    //   if (this.availableStudents) {
    //     this.students = this.filteredStudents;
    //   } else {
    //     this.getStudents();
    //   }
    // },

    idStudents() {
      if (this.filteredStudents.length > 0) {
        this.addCheckedStudents();
      }
    },
  },

  computed: {
    filteredStudents() {
      if (this.$store.state.student.students) {
        if (this.availableStudents) {
          let filtered = [];
          this.availableStudents.forEach((stuId) => {
            let student = this.$store.state.student.students.find(
              (s) => s.id === stuId
            );
            if (student) {
              filtered.push(student);
            }
          });
          return filtered;
        } else {
          return this.$store.state.student.students;
        }
      } else return [];
    },
  },

  methods: {
    save() {
      this.$emit("save");
    },

    getStudentsToStorage() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.STUDENT_GET_ALL)
        .then(() => {
          if (this.idStudents) {
            this.addCheckedStudents();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addCheckedStudents() {
      if (this.idStudents) {
        this.idStudents.forEach((id) => {
          let student = this.filteredStudents.find((s) => s.id === id);
          if (student) {
            this.checkedRows.push(student);
          }
        });
      }
    },

    addStudentToClassroom(studentsChecked) {
      let idStudents = studentsChecked.map((q) => q.id);
      this.$emit("addStudents", idStudents);
    },

    removeStudentFromClassroom(studentsChecked) {
      let idStudents = studentsChecked.map((q) => q.id);
      this.$emit("removeStudents", idStudents);
    },

    selectionStudent(student) {
      this.$emit("selectionStudent", student);
    },

    showExam($event, id) {
      this.showExamId = id;
      this.isModalActive = !this.isModalActive;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: green;
}
.exam-modal {
  background-color: white;
}
</style>