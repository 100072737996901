<template>
  <div class="p-1">
    <header class="card-header skyColor mb-5">
      <p class="subtitle card-header-title pl-5 pt-4 pb-4 mb-0">{{t('componentNames.classroomCreator')}}</p>
      <UserFeedback :target="t('componentNames.classroomCreator')" class="end mr-4 mt-1"></UserFeedback>
    </header>

    <ClassroomCreatorCourseSelect
      class="mt-2 mb-3"
      @selectedCourse="selectedCourse"
      :refreshSelect="refreshSelectCourse"
    ></ClassroomCreatorCourseSelect>
    <template v-if="selectCourse">
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form>
          <b-field>
            <ValidationProvider rules="required" v-slot="{ errors, valid }">
              <b-field
                :label="t('classroom.type')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="t(errors[0])"
                grouped
                group-multiline
              >
                <b-radio-button
                  v-for="option in mClassroomTypes"
                  :key="option.id"
                  v-model="radioButton"
                  :native-value="option"
                  type="is-primary"
                  @input="newClassroom.type = option.id"
                >
                  <b-icon :icon="option.iconName"></b-icon>
                  <span>{{ option.name }}</span>
                </b-radio-button>
              </b-field>
            </ValidationProvider>
          </b-field>
          <b-field>
            <ValidationProvider rules="required|max:99" v-slot="{ errors, valid }">
              <b-field
                :label="t('classroom.name')"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="t(errors[0], { max: 99 })"
              >
                <b-input type="text" v-model="newClassroom.name" :has-counter="false" required></b-input>
              </b-field>
            </ValidationProvider>
          </b-field>
          <b-field grouped group-multiline>
            <b-field
              :label="t('classroom.datetimeLabel')"
              :message="messageDateTime"
              :type="{
                'is-danger': messageDateTime != null,
                'is-success': messageDateTime == null,
              }"
              grouped
              group-multiline
              class="mb-4"
            >
              <b-field>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('classroom.day')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-datepicker
                      v-model="start.date"
                      icon="calendar-today"
                      :min-date="minDate"
                      :maxDate="maxDate"
                    ></b-datepicker>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('classroom.time')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-clockpicker v-model="start.time" editable icon="clock-time-two-outline"></b-clockpicker>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>

            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }">
                <b-field
                  :label="t('classroom.timeLabel')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-numberinput
                    controls-position="compact"
                    controls-alignment="right"
                    v-model="timeClassroom"
                    max="180"
                    min="0"
                  />
                </b-field>
              </ValidationProvider>
            </b-field>
          </b-field>
          <b-field class="pt-3">
            <b-switch
              :rounded="false"
              v-model="newClassroom.startOnTime"
            >{{ newClassroom.startOnTime ? t('classroom.startOnTime') : t('classroom.dontStartOnTime') }}</b-switch>
          </b-field>
          <b-field>
            <b-field :label="t('classroom.addTags')">
              <b-taginput
                v-model="newClassroom.tags"
                ellipsis
                icon="label"
                :placeholder="t('common.add')"
                :aria-close-label="t('common.remove')"
              ></b-taginput>
            </b-field>
          </b-field>
          <b-field>
            <b-collapse
              aria-id="selectTeacher"
              class="panel shadow-local"
              animation="slide"
              v-model="teachersIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('classroom.teacher')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="tempTeachername"
                      :has-counter="false"
                      required
                      :readonly="true"
                      expanded
                    ></b-input>
                    <p class="control">
                      <b-button aria-controls="selectTeacher" v-if="isAdmin">{{t('classroom.addTeacher')}}</b-button>
                    </p>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="p-4" v-if="isAdmin">
                <ClassroomCreatorTeachers
                  :availableTeachers="currentCourse.teachers"
                  :idTeacher="newClassroom.teacher"
                  @setTeacher="setTeacher"
                  @save="teachersIsOpen = !teachersIsOpen"
                />
              </div>
            </b-collapse>
          </b-field>
          <b-field>
            <b-collapse
              aria-id="selectStudents"
              class="panel shadow-local"
              animation="slide"
              v-model="studentsIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <b-field :label="t('classroom.studentList')" label-position="on-border">
                  <b-input v-model="newClassroom.users.length" expanded :readonly="true"></b-input>
                  <p class="control">
                    <b-button aria-controls="selectStudents">{{t('classroom.addStudents')}}</b-button>
                  </p>
                </b-field>
              </div>
              <div class="p-4">
                <ClassroomCreatorStudents
                  :availableStudents="currentCourse.students"
                  @addStudents="addStudents"
                  @removeStudents="removeStudents"
                  @save="studentsIsOpen = !studentsIsOpen"
                />
              </div>
            </b-collapse>
          </b-field>
          <b-field v-if="newClassroom.type > 0">
            <b-collapse
              aria-id="selectExam"
              class="panel shadow-local"
              animation="slide"
              v-model="examIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('classroom.exam')"
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-input
                      v-model="newClassroom.exam.title"
                      :has-counter="false"
                      required
                      :readonly="true"
                      expanded
                    ></b-input>
                    <b-button aria-controls="selectExam">{{t('classroom.selectExam')}}</b-button>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="section">
                <ClassroomCreatorExam
                  :typeShow="radioButton.examType"
                  @selectionExam="selectedExam"
                  @save="examIsOpen = !examIsOpen"
                ></ClassroomCreatorExam>
              </div>
            </b-collapse>
          </b-field>
          <div v-if="newClassroom.type === 0">
            <b-collapse
              aria-id="selectDeepZoom"
              class="panel shadow-local"
              animation="slide"
              v-model="deepzoomIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <b-field
                  :label="t('classroom.deepZooms')"
                  label-position="on-border"
                  :message="messageImages"
                  :type="{
                    'is-danger': messageImages != null,
                    'is-success': messageImages == null,
                  }"
                >
                  <b-taginput
                    v-model="newClassroom.images"
                    :has-counter="false"
                    required
                    :readonly="true"
                    :closable="false"
                    type="is-primary"
                    expanded
                  ></b-taginput>
                  <b-button aria-controls="selectDeepZoom">{{t('classroom.selectDeepZoom')}}</b-button>
                </b-field>
              </div>
              <div class="section">
                <ClassroomCreatorDeepzooms
                  @addDeepzooms="addDeepzoom"
                  @removeDeepzooms="removeDeepzoom"
                  @save="deepzoomIsOpen = !deepzoomIsOpen"
                ></ClassroomCreatorDeepzooms>
              </div>
            </b-collapse>

            <b-collapse
              aria-id="selectMedia"
              class="panel shadow-local"
              animation="slide"
              v-model="mediaIsOpen"
            >
              <div slot="trigger" class="local-panel">
                <b-field :label="t('classroom.multimedia')" label-position="on-border">
                  <b-taginput
                    v-model="nameMedia"
                    :has-counter="false"
                    required
                    :readonly="true"
                    :closable="false"
                    type="is-primary"
                    expanded
                  ></b-taginput>
                  <b-button aria-controls="selectMedia">{{t('classroom.selectMultimedia')}}</b-button>
                </b-field>
              </div>
              <div class="section">
                <template v-if="isAdmin">
                  <ClassroomCreatorMultimedia
                    @addMultimedias="addMedia"
                    @removeMultimedias="removeMedia"
                    @save="mediaIsOpen = !mediaIsOpen"
                    :idOwner="newClassroom.teacher"
                  ></ClassroomCreatorMultimedia>
                </template>
                <template v-else>
                  <ClassroomCreatorMultimedia
                    @addMultimedias="addMedia"
                    @removeMultimedias="removeMedia"
                    @save="mediaIsOpen = !mediaIsOpen"
                  ></ClassroomCreatorMultimedia>
                </template>
              </div>
            </b-collapse>
          </div>

          <hr />
          <div class="buttons">
            <b-button
              type="is-success"
              icon-left="content-save"
              @click="saveClassroom"
              :disabled="invalid || messageImages || messageDateTime"
              :loading="isLoading"
            >{{t('common.save')}}</b-button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Action } from "../../../store/config_actions";
import ClassroomCreatorExam from "./ClassroomCreatorExams";
import ClassroomCreatorStudents from "./ClassroomCreatorStudents";
import ClassroomCreatorTeachers from "./ClassroomCreatorTeachers";
import ClassroomCreatorCourseSelect from "./ClassroomCreatorCourseSelect";
import ClassroomCreatorDeepzooms from "./ClassroomCreatorDeepzoom";
import ClassroomCreatorMultimedia from "./ClassroomCreatorMultimedia";
import UserFeedback from "@/components/feedbacks/UserFeedback";

const today = new Date();

export default {
  name: "ClassroomCreator",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    ValidationProvider,
    ValidationObserver,
    ClassroomCreatorExam,
    ClassroomCreatorStudents,
    ClassroomCreatorTeachers,
    ClassroomCreatorCourseSelect,
    ClassroomCreatorDeepzooms,
    ClassroomCreatorMultimedia,
    UserFeedback,
  },

  props: {},

  data: function () {

    return {
      isLoading: false,
      examIsOpen: false,
      studentsIsOpen: false,
      teachersIsOpen: false,
      deepzoomIsOpen: false,
      mediaIsOpen: false,
      tags: [],
      idQ: 1,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      maxDate: null,
      start: {
        date: undefined,
        time: undefined,
      },
      timeClassroom: null,
      end: {
        date: undefined,
        time: undefined,
      },
      radioButton: "",
      tempTeachername: null,
      newClassroom: {
        type: "",
        name: "",
        starts: "", //date-time
        ends: "", //date-time
        teacher: null,
        users: [],
        exam: {},
        shapeData: "",
        tags: [],
        images: [],
        media: [],
        startOnTime: true,
        stopOnTime: false
      },
      selectCourse: null,
      refreshSelectCourse: 0,
      messageDateTime: null,
      messageImages: null,
      images: [],
      myFiles: [],
      deepZoomConfig: {},
    };
  },

  mounted() {
    if (this.isOnlyTeacher) {
      this.setLocalTeacher();
    }
    this.getDeepZoomConfig();
    this.getMyFiles();
  },

  watch: {
    currentTime() {
      this.newClassroom.starts = this.formatDate(this.start);
      if (!this.mBeforeNow(this.newClassroom.starts)) {
        this.messageDateTime = this.t('validation.messageDatetime') //"Data nie może być wcześniejsza niż aktualna";
      } else {
        this.messageDateTime = null;
      }
    },

    currentImages() {
      if (this.newClassroom.type === 0) {
        if (this.newClassroom.images.length === 0) {
          this.messageImages = this.t('validation.messageImages') //"Musisz wybrać preparaty do wykładu";
        } else {
          this.messageImages = null;
        }
      } else {
        this.messageImages = null;
      }
    },

    clasroomCurrentType() {
      if (this.newClassroom.type === 0) {
        this.newClassroom.exam = {};
      } else {
        (this.newClassroom.images = []), (this.newClassroom.media = []);
      }
    },

    selectCourse() {
      if (this.selectCourse) {
        let date = new Date(this.mDate(this.selectCourse.starts))
        this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())

        let max = new Date(this.selectCourse.ends)
        this.maxDate = new Date(max.getFullYear(), max.getMonth(), max.getDate())
      } else {
        this.minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        this.maxDate = null
      }
    }
  },

  computed: {
    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    isOnlyTeacher() {
      if (
        this.session &&
        ["Teacher"].every((r) => this.session.roles.includes(r))
      )
        return true;
      else return false;
    },

    myId() {
      if (this.session) return this.session.id;
      else return null;
    },

    myName() {
      if (this.isTeacher) {
        var firstName = this.$store.state.auth.session.firstName;
        var lastName = this.$store.state.auth.session.lastName;
        return firstName.concat(" ", lastName);
      }
      return "";
    },

    currentCourse() {
      return this.selectCourse;
    },

    currentTime() {
      return this.start.time;
    },

    nameMedia() {
      let names = [];
      this.newClassroom.media.forEach((element) => {
        let media = this.$store.state.media.myFiles.find(
          (m) => m.id === element
        );
        if (media) {
          names.push(media.originalFileName);
        }
      });
      return names;
    },

    currentImages() {
      if (this.newClassroom.type === 0) {
        return this.newClassroom.images;
      } else return [];
    },

    clasroomCurrentType() {
      return this.newClassroom.type;
    },
  },

  methods: {
    saveClassroom() {
      this.newClassroom.course = this.selectCourse.id;
      let timeStarts = this.formatDate(this.start);
      let timeEnds = this.mDatePlusMinutes(
        timeStarts,
        this.timeClassroom
      ); // this.formatDate(this.end);

      this.newClassroom.starts = this.mDateTimeRaw(timeStarts);
      this.newClassroom.ends = this.mDateTimeRaw(timeEnds);

      if (!this.mBeforeNow(this.newClassroom.starts)) {
        this.mDangerSnack(this.t('validation.messageDatetime'));
      } else {
        this.addNewClassroom();
      }
    },

    selectedCourse(value) {
      this.getCourse(value);
    },

    formatDate(dateToFormat) {
      var dateString = this.mDate(dateToFormat.date);
      var timeString = this.mTime(dateToFormat.time);
      return this.mDateTimeRaw2(dateString.concat(" ", timeString));
    },

    selectedExam(exam) {
      this.newClassroom.exam = exam;
    },

    setTeacher(teacher) {
      if (teacher.id) {
        this.tempTeachername = teacher.firstName.concat(" ", teacher.lastName);
        this.newClassroom.teacher = teacher.id;
      } else {
        this.tempTeachername = null;
        this.newClassroom.teacher = "";
      }
    },

    setLocalTeacher() {
      if (this.isTeacher) {
        this.tempTeachername = this.myName;
        this.newClassroom.teacher = this.myId;
      }
    },

    addStudents(idStudents) {
      idStudents.forEach((element) => {
        this.newClassroom.users.push(element);
      });
    },

    removeStudents(idStudents) {
      idStudents.forEach((element) => {
        this.newClassroom.users.splice(
          this.newClassroom.users.indexOf(element),
          1
        );
      });
    },

    addDeepzoom(idDeepzooms) {
      idDeepzooms.forEach((element) => {
        if (!this.newClassroom.images.includes(element)) {
          this.newClassroom.images.push(element);
        }
      });
    },

    removeDeepzoom(idDeepzooms) {
      idDeepzooms.forEach((element) => {
        if (this.newClassroom.images.includes(element)) {
          this.newClassroom.images.splice(
            this.newClassroom.images.indexOf(element),
            1
          );
        }
      });
    },

    addMedia(idMedias) {
      idMedias.forEach((element) => {
        if (!this.newClassroom.media.includes(element)) {
          this.newClassroom.media.push(element);
        }
      });
    },

    removeMedia(idMedias) {
      idMedias.forEach((element) => {
        if (this.newClassroom.media.includes(element)) {
          this.newClassroom.media.splice(
            this.newClassroom.media.indexOf(element),
            1
          );
        }
      });
    },

    getCourse(id) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_GET, id)
        .then((payload) => {
          this.selectCourse = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addNewClassroom() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_ADD, this.newClassroom)
        .then((payload) => {
          this.mSuccessSnack(this.t('classroom.successAdded'));
          this.updateCourse(payload.id);
          this.newClassroom = {
            type: "",
            name: "",
            starts: "", //date-time
            ends: "", //date-time
            teacher: null,
            users: [],
            exam: {},
            shapeData: "",
            tags: [],
            images: [],
            media: [],
            startOnTime: true,
            stopOnTime: false
          };
          this.tempTeachername = null;
          this.start = {
            date: undefined,
            time: undefined,
          };
          this.timeClassroom = null;
          this.selectCourse = null;
          this.refreshSelectCourse++;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    updateCourse(idClass) {
      this.isLoading = true;
      this.currentCourse.classrooms.push(idClass);
      this.$store
        .dispatch(Action.COURSE_MODIFY, this.currentCourse)
        .then(() => {
          this.mSuccessSnack(this.t('course.successUpdate'));
          this.$emit("classroomAdded");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getDeepZoomConfig() {
      this.isLoading = true;
      if (this.$store.state.deepzoom.config) {
        this.deepZoomConfig = this.$store.state.deepzoom.config;
        this.images = this.deepZoomConfig.images;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.DEEPZOOM_CONFIG_GET)
          .then((payload) => {
            this.deepZoomConfig = payload;
            this.images = payload.images;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
          });
      }
    },

    getMyFiles() {
      this.isLoading = true;
      if (this.$store.state.media.myFiles) {
        this.myFiles = this.$store.state.media.myFiles;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.MEDIA_GET_MY)
          .then((payload) => {
            this.myFiles = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.card {
  overflow: visible !important;
}
div.end {
  float: right;
}
.local-panel {
  background-color: #ededed;
  color: #363636;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.shadow-local {
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid #ededed;
}

</style>
