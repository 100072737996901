<template>
  <div class="container is-fluid">
    <b-field grouped group-multiline>
      <b-select v-model="perPage" size="is-small">
        <option value="2">2</option>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </b-select>
    </b-field>
    <b-table
      :data="myFiles"
      :loading="isLoading"
      striped
      paginated
      :per-page="perPage"
      checkable
      :checked-rows.sync="checkedRows"
    >
      <b-table-column
        field="originalFileName"
        searchable
        sortable
        :label="t('common.fileName')"
        v-slot="props"
        >{{ props.row.originalFileName }}</b-table-column
      >
      <b-table-column
        field="description"
        searchable
        sortable
        :label="t('forms.description')"
        v-slot="props"
        >{{ props.row.description }}</b-table-column
      >
      <b-table-column
        field="tags"
        searchable
        sortable
        :label="t('forms.tags')"
        v-slot="props"
      >
        <b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag">
            {{ tag }}
          </b-tag>
        </b-taglist>
      </b-table-column>
    </b-table>
    <hr />
    <div class="buttons is-centered">
      <b-button type="is-success" :loading="isLoading" @click="save">
        {{t('common.save')}}
      </b-button>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";

export default {
  name: "ClassroomCreatorMultimedias",
  mixins: [CommonHelpers],
  components: {},

  props: {
    idMultimedias: { type: Array, required: false },
    idOwner: { type: String, required: false },
  },

  data: function () {
    return {
      questions: [],
      perPage: 50,
      checkedRows: [],
      isLoading: false,
    };
  },

  mounted() {
    if (this.isAdmin) {
      this.getFiles();
    } else {
      this.getMyFiles();
    }
  },

  watch: {
    checkedRows: function (newValue, oldValue) {
      let difference;
      if (newValue.length > oldValue.length) {
        difference = newValue.filter((x) => !oldValue.includes(x));
        this.addMultimediaToClassroom(difference);
      } else {
        difference = oldValue.filter((x) => !newValue.includes(x));
        this.removeMultimediaFromClassroom(difference);
      }
    },

    idMultimedias() {
      if (this.myFiles.length > 0) {
        this.addCheckedMultimedias();
      }
    },
  },

  computed: {
    myFiles() {
      if (this.isAdmin) {
        if (this.$store.state.media.files) {
          let filtered = [];
          if (this.idOwner) {
            filtered = this.$store.state.media.files.filter(
              (f) => f.owner === this.idOwner
            );
          }

          return filtered;
        } else return [];
      } else {
        return this.$store.state.media.myFiles;
      }
    },

    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },
  },

  methods: {
    save() {
      this.$emit("save");
    },

    getMyFiles() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_GET_MY)
        .then(() => {
          this.addCheckedMultimedias();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getFiles() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_GET_ALL)
        .then(() => {
          this.addCheckedMultimedias();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addCheckedMultimedias() {
      if (this.idMultimedias) {
        this.idMultimedias.forEach((id) => {
          let multimedia = this.myFiles.find((s) => s.id === id);
          if (multimedia) {
            this.checkedRows.push(multimedia);
          }
        });
      }
    },

    addMultimediaToClassroom(multimediasChecked) {
      let idMultimedias = multimediasChecked.map((q) => q.id);
      this.$emit("addMultimedias", idMultimedias);
    },

    removeMultimediaFromClassroom(multimediasChecked) {
      let idMultimedias = multimediasChecked.map((q) => q.id);
      this.$emit("removeMultimedias", idMultimedias);
    },

    selectionMultimedia(multimedia) {
      this.$emit("selectionMultimedia", multimedia);
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: green;
}
</style>