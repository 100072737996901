<template>
  <div>
    <div class="columns">
      <div v-if="teacherCourse" class="column is-one-quarter">
        <div class="columns">
          <div class="column">
            <b-field :label="t('common.from')" label-position="on-border">
              <b-datepicker v-model="date.from" icon="calendar-today"></b-datepicker>
            </b-field>
          </div>
          <div class="column">
            <b-field :label="t('common.to')" label-position="on-border">
              <b-datepicker v-model="date.to" icon="calendar-today" :min-date="date.from"></b-datepicker>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column">
        <!-- <b-dropdown v-model="selectCourse" expanded :scrollable="true"
            :max-height="200">
          <template #trigger>
                <b-button
                    icon-right="menu-down"
                    type="is-primary"
                    expanded>
                    <span v-if="selectCourse">{{selectCourse.name}} - poziom: {{mGetLevelCourseName(selectCourse.type)}} ({{mDate(selectCourse.starts)}} - {{mDate(selectCourse.ends)}})</span>
                   <span v-else> Wybierz kurs</span>
                   
                </b-button>
            </template>
            <b-dropdown-item disabled>
                <div class="columns">
                  <div class="column is-one-quarter"><strong>Uczelnia</strong></div> 
                  <div class="column is-one-quarter"><strong>Nazwa</strong></div>
                  <div class="column is-one-quarter"><strong>Poziom</strong></div>
                  <div class="column is-one-quarter"><strong>Data</strong></div>
              </div>
            </b-dropdown-item>
          <b-dropdown-item
                v-for="(course, index) in courses"
                :key="index"
                :value="course" aria-role="listitem">
                <div class="columns">
                  <div class="column is-one-quarter">{{course.academy}}</div> 
                  <div class="column is-one-quarter"> {{course.name}}</div>
                  <div class="column is-one-quarter">{{mGetLevelCourseName(course.type)}} </div>
                  <div class="column is-one-quarter">{{mDate(course.starts)}} - {{mDate(course.ends)}}</div>
              </div>
            </b-dropdown-item>
        </b-dropdown>-->
        <b-field :label="t('common.courses')" label-position="on-border">
          <b-autocomplete
            v-model="courseName"
            open-on-focus
            :placeholder="t('classroom.courseMustSelected')"
            :data="filteredCourses"
            field="name"
            :custom-formatter="getCourseName"
            @select="(option) => (selectCourse = option)"
            :keep-first="true"
          >
            <template slot-scope="props">
              <div style="white-space: pre-wrap">
                <p>
                  <strong>{{ props.option.name }}</strong>
                  , {{t('common.level')}}: {{ mGetLevelCourseName(props.option.type) }}
                </p>
                <p>{{t('common.academy')}}: {{ props.option.academy }}</p>
                <p>
                  {{ mDate(props.option.starts) }} -
                  {{ mDate(props.option.ends) }}
                </p>
              </div>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import { Action } from "@/store/config_actions";

export default {
  name: "ClassroomCreatorCourseSelect",
  mixins: [CommonHelpers, TypesHelpers],
  components: {},

  props: {
    refreshSelect: { type: Number, required: true },
  },

  data: function () {
    return {
      courses: [],
      date: {
        from: null,
        to: null,
      },
      teacherCourse: false,
      selectCourse: null,
      courseName: "",
    };
  },

  mounted() {
    if (this.isAdmin && !this.isTeacher) {
      this.teacherCourse = false;
      this.getCourses();
    } else if (this.isAdmin && this.isTeacher) {
      this.teacherCourse = false;
      this.getCourses();
    } else {
      this.teacherCourse = true;
      this.date.to = new Date(this.mNowPlusDays(30));
      this.date.from = new Date(this.mNow());
    }
  },

  watch: {
    dateFrom() {
      if (this.date.to && this.date.from) {
        this.getMyCourses();
      }
    },

    dateTo() {
      if (this.date.to && this.date.from) {
        this.getMyCourses();
      }
    },

    selectCourse() {
      if (this.selectCourse && this.selectCourse.id) {
        this.selectedCourse();
      }
    },

    refreshSelect() {
      this.selectCourse = null;
    },
  },

  computed: {
    session() {
      return this.$store.state.auth.session;
    },
    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    myAcademy() {
      if (this.session && this.session.academy) return this.session.academy;
      else return " ";
    },

    dateFrom() {
      return new Date(this.date.from);
    },

    dateTo() {
      return new Date(this.date.to);
    },

    filteredCourses() {
      return this.courses.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.courseName.toLowerCase()) >= 0
          );
        } else return false
      });
    },
  },

  methods: {
    selectedCourse() {
      this.$emit("selectedCourse", this.selectCourse.id);
    },

    getCourseName(option) {
      if (option) {
        return `${option.name} - ${this.t('common.level')?.toLowerCase()}: ${this.mGetLevelCourseName(option.type)} (${this.mDate(option.starts)} - ${this.mDate(option.ends)})`
      }
    },
    /**
     * Download all courses only if admin
     */
    getCourses(filterAcademy) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_GET_ALL)
        .then((payload) => {
          if (filterAcademy) {
            this.courses = payload;
          } else {
            this.courses = payload;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /**
     * Download courses
     */
    getMyCourses() {
      if (!this.isAdmin) {
        this.isLoading = true;
        let sendedDate = JSON.parse(JSON.stringify(this.date));
        if (sendedDate.to) sendedDate.to = this.mDate(sendedDate.to);
        if (sendedDate.from) sendedDate.from = this.mDate(sendedDate.from);
        this.$store
          .dispatch(Action.COURSE_GET_TEACHER_COURSES, sendedDate)
          .then((payload) => {
            this.courses = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
