<template>
  <div class="container is-fluid">
    <p class="subtitle">{{t('exam.titleList')}}</p>
    <b-field grouped group-multiline>
      <b-select v-model="perPage">
        <option value="2">2</option>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </b-select>
      <b-button
        :loading="isLoading"
        @click="getExams"
        type="is-primary"
        class="is-pulled-right"
        icon-left="refresh"
        size="is-small"
      >{{t('common.refresh')}}</b-button>
    </b-field>
    <b-table
      :data="exams"
      :show-detail-icon="showDetailIcon"
      detailed
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
      :loading="isLoading"
      striped
      paginated
      :per-page="perPage"
      checkable
      :checked-rows.sync="checkedRows"
    >
      <b-table-column field="type" :label="t('common.type')" v-slot="props">
        <b-icon v-if="props.row.type === 0" icon="format-list-checkbox"></b-icon>
        <b-icon v-else-if="props.row.type === 1" icon="file-document-edit"></b-icon>
        <b-icon v-else-if="props.row.type === 2" icon="file-document-edit"></b-icon>
        <b-icon v-else icon="head-question"></b-icon>
      </b-table-column>
      <b-table-column
        field="title"
        :label="t('forms.title')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.title }}</b-table-column>
      <b-table-column
        field="description"
        :label="t('forms.description')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.description }}</b-table-column>
      <b-table-column
        field="maxTime"
        :label="t('forms.time')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.maxTime }}</b-table-column>
      <b-table-column
        field="questions"
        :label="t('forms.numberQuestions')"
        v-slot="props"
        searchable
        sortable
      >{{ props.row.questions.length }}</b-table-column>
      <b-table-column v-slot="props">
        <div class="buttons">
          <b-button icon-left="eye" type="is-info is-light" @click="showExam($event,props.row.id)"></b-button>
        </div>
      </b-table-column>
    </b-table>
    <hr />
    <div class="buttons is-centered">
      <b-button type="is-success" :loading="isLoading" @click="save">{{t('common.save')}}</b-button>
    </div>
    <b-modal v-model="isModalActive">
      <div class="modal-content exam-modal">
        <ExamPreview :idExam="showExamId" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../../store/config_actions";
import ExamPreview from "../../exam/examMentor/ExamPreview"

export default {
  name: "ClassroomCreatorExam",
  mixins: [CommonHelpers],
  components: { ExamPreview },

  props: {
    typeShow: { type: Number, required: true },
    idExam: { type: String, required: false }
  },

  data: function () {
    return {
      questions: [],
      exams: [],
      perPage: 50,
      showDetailIcon: false,
      defaultOpenedDetails: [0],
      checkedRows: [],
      isLoading: false,
      isModalActive: false,
      showExamId: null,
    };
  },

  mounted() {
    this.getExams();
  },

  watch: {
    checkedRows: function (newValue, oldValue) {

      if (newValue.length > oldValue.length) {
        let difference = newValue.filter((x) => !oldValue.includes(x));
        this.checkedRows = difference;
      }

      if (this.checkedRows.length > 0) {
        this.selectionExam(this.checkedRows[0])
      }
      else {
        this.selectionExam({});
      }

    },

    typeShow: function () {
      this.getExams();
    },
  },

  computed: {},

  methods: {
    getQuestions() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_GET_ALL)
        .then((payload) => {
          this.questions = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getExams() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.EXAM_GET_ALL)
        .then((payload) => {
          let filteredList = payload.filter((q) => q.type === this.typeShow);
          if (filteredList) {
            this.exams = filteredList;
            this.checkedExamInRows();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    checkedExamInRows() {
      this.checkedRows = [];
      if (this.idExam) {
        let examToCheck = this.exams.find((ex) => ex.id === this.idExam);
        if (examToCheck) {
          this.checkedRows.push(examToCheck);
        }
      }
    },

    save() {
      this.$emit("save");
    },

    selectionExam(exam) {
      this.$emit("selectionExam", exam);
    },

    showExam($event, id) {
      this.showExamId = id;
      this.isModalActive = !this.isModalActive;
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: green;
}
.exam-modal {
  background-color: white;
}
</style>